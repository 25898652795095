.submcq-component-container {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 20px;
    gap: 30px;
}
.go-back-div {
  display: flex !important;
  align-items: start;
  justify-content: start !important;
  position: fixed;
  z-index: 1;
  margin-top: -50px;
  }
  .error {
    color: red;
    font-size: 14px;
  }
  .spinner-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loader-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    color: #796dec ;
  }
@media (min-width: 662px) {
    .submcq-component-container {
      grid-template-columns: 1fr;
      margin-top: 50px;
      gap: 30px;
    }
  }
  
  @media (min-width: 992px) {
    .submcq-component-container {
      grid-template-columns: 1fr 1fr;
    }
  }