.main-container {
  position: relative;
}
.btn-bulk-add, .btn-stu-add {
  z-index: 1111;
  border: none;
  padding: 7px 10px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  background: #796dec !important;
  color: white;
  position: fixed;
  padding: 10px 20px;
  z-index: 99;
}
.btn-stu-add {
  right: 25px;
  bottom: 25px;
}
.btn-bulk-add {
  right: 190px;
  bottom: 25px;
}
.course-progress-card {
  padding: 16px 10px;
}

.student-span {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #796dec !important;
  padding: 2px 6px;
}
.student-span-text {
  color: black;
}
.each-progress-head {
  color: #ffff;
  background-color: #796dec;
  border-radius: 0px;
  padding: 5px;
  font-size: 14px;
  margin-top: 20px;
}

.text-field-main-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.text-field-container {
  display: flex;
  justify-content: center;
  gap: 50px;
}
.select-style {
  width: 200px;
}
.select-heading {
  color: #796dec;
}
.submit-button {
  text-transform: none !important;
  background-color: #796dec !important;
  color: white !important;
}
.prg-cls {
  color: white !important;
  background-color: #796dec !important;
  border: none !important;
  border-radius: 3px !important;
}
.exam-progress-head {
  color: #796dec !important;
}
.grid-data {
  cursor: pointer !important;
}

.excel-upload {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

@media (min-width: 662px) {
  .each-progress-head {
    color: #ffff;
    background-color: #796dec;
    border-radius: 5px;
    padding: 10px;
    font-size: 20px;
    margin-top: 20px;
  }
  /* .select-input {
      width: 200px !important;
      height: 40px !important;
    } */
}
@media (min-width: 992px) {
}
