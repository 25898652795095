.app-bar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    color:#796dec ;
}

.b-name, .logout{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}
.brand-logo {
    width: 30px !important;
    height: 30px !important;
}
.win {
    color: gray;
}
.score {
    color: #796dec;
}
